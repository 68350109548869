
import { Component, Mixins } from 'vue-property-decorator';

import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class RequestMaintenance extends Mixins(View) {}
